// Used as a fallback if the API is down

export const hardcodedRates = {
    "Australian Dollar (AUD)": 1.5392802922,
    "Bulgarian Lev (BGN)": 1.7781102999,
    "Brazilian Real (BRL)": 4.9004308773,
    "Canadian Dollar (CAD)": 1.3443201767,
    "Swiss Franc (CHF)": 0.8767301001,
    "Chinese Yuan (CNY)": 7.2387010756,
    "Czech Koruna (CZK)": 21.9700534089,
    "Danish Krone (DKK)": 6.8079108152,
    "Euro (EUR)": 0.9134801106,
    "British Pound (GBP)": 0.7878600901,
    "Hong Kong Dollar (HKD)": 7.8160608857,
    "Croatian Kuna (HRK)": 7.0418712792,
    "Hungarian Forint (HUF)": 349.7298199729,
    "Indonesian Rupiah (IDR)": 15196.810558816,
    "Israeli Shekel (ILS)": 3.7491006603,
    "Indian Rupee (INR)": 82.8475011016,
    "Icelandic Krona (ISK)": 131.4663844836,
    "Japanese Yen (JPY)": 145.0034138135,
    "South Korean Won (KRW)": 1329.207706231,
    "Mexican Peso (MXN)": 17.0399319886,
    "Malaysian Ringgit (MYR)": 4.5866005184,
    "Norwegian Krone (NOK)": 10.4271016235,
    "New Zealand Dollar (NZD)": 1.6713103091,
    "Philippine Peso (PHP)": 56.5777793964,
    "Polish Zloty (PLN)": 4.0479807547,
    "Romanian Leu (RON)": 4.5127605347,
    "Russian Ruble (RUB)": 99.5564450298,
    "Swedish Krona (SEK)": 10.8334813592,
    "Singapore Dollar (SGD)": 1.3513002315,
    "Thai Baht (THB)": 35.0884062283,
    "Turkish Lira (TRY)": 27.0268552655,
    "United States Dollar (USD)": 1,
    "South African Rand (ZAR)": 18.9268628665
  };