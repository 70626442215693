import { atom } from 'recoil';

export const currencyListState = atom({
  key: 'currencyListState',
  default: [
    "Australian Dollar (AUD)",
    "Bulgarian Lev (BGN)",
    "Brazilian Real (BRL)",
    "Canadian Dollar (CAD)",
    "Swiss Franc (CHF)",
    "Chinese Yuan (CNY)",
    "Czech Koruna (CZK)",
    "Danish Krone (DKK)",
    "Euro (EUR)",
    "British Pound Sterling (GBP)",
    "Hong Kong Dollar (HKD)",
    "Croatian Kuna (HRK)",
    "Hungarian Forint (HUF)",
    "Indonesian Rupiah (IDR)",
    "Israeli New Shekel (ILS)",
    "Indian Rupee (INR)",
    "Icelandic Króna (ISK)",
    "Japanese Yen (JPY)",
    "South Korean Won (KRW)",
    "Mexican Peso (MXN)",
    "Malaysian Ringgit (MYR)",
    "Norwegian Krone (NOK)",
    "New Zealand Dollar (NZD)",
    "Philippine Peso (PHP)",
    "Polish Złoty (PLN)",
    "Romanian Leu (RON)",
    "Russian Ruble (RUB)",
    "Swedish Krona (SEK)",
    "Singapore Dollar (SGD)",
    "Thai Baht (THB)",
    "Turkish Lira (TRY)",
    "US Dollar (USD)",
    "South African Rand (ZAR)",
  ], 
});